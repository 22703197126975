.loginWrapper {
    width: 80%;
    height: 80vh;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 10vh 10%;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0px 0px 34px rgba(145, 173, 253, 0.4);
    
    .loginLeft, .loginRight {
        width: 50%;
        height: 100%;
    }

    .loginRight {
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: linear-gradient(rgba(59, 109, 255, 0.4), rgba(59, 109, 255, 0.4)), url('../assets/login-image.png');

        .contentBox {
            width: 400px;
            height: 300px;
            position: absolute;
            top: calc(50% - 150px);
            left: calc(50% - 200px);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 30px;
            border-radius: 18px;
            backdrop-filter: blur(33.25px);
            background: rgba(255, 255, 255, 0.2);

            h3 {
                color: #fff;
                font-size: 36px;
                font-weight: 600;
            }

            p {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    
    .loginLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
            height: 28px;
            object-fit: contain;
        }

        h3 {
            font-size: 28px;
            font-weight: 700;
            color: #100F14;
            margin-top: 40px;
        }

        h4 {
            font-size: 24px;
            font-weight: 600;
            color: #100F14;
            margin-top: 40px;
        }

        p {
            color: #100F14;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 30px;
        }
    }

    .login-input {
        height: 50px;
        width: 100%;
        margin-bottom: 12px;
    }
    
    .login-button {
        height: 50px;
        width: 100%;
        color: #fff;
        font-size: 15px;
        margin-top: 12px;
        background-color: #3B6DFF;
    }
}
